<template>
  <custom-select
    v-bind="$attrs"
    v-on="$listeners"
    :options="daysOfTheMonth"
    :value="value"
    display-type="grid"
    :grid-columns="4"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    daysOfTheMonth() {
      const days = [];
      for (var i = 0; i < 31; i++) {
        const day = i+1;
        const value = day;
        const title = day.toPosition();
        days.push({ title, value });
      }
      return days;
    },
  },
};
</script>
